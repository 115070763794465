import classNames from 'classnames';
import React from 'react';

type Alerts = 'alert' | 'warning' | 'success';

interface Props {
  type?: Alerts;
  testid: string;
  button?: boolean;
  className: string;
}

const Flash: React.FC<Props> = ({
  type,
  testid,
  button,
  children,
  className,
}) => {
  const classes = classNames({
    flash: true,
    [`flash--${type}`]: true,
    'flash--button': button,
    [className]: !!className,
  });

  const iconName = type === 'success' ? 'check-circle' : 'exclamation-triangle';
  return (
    <div role="alert" className={classes} data-testid={testid} data-cy={testid}>
      <i className={`fas fa-${iconName} space__icon`} />
      <div className="flash__inner">{children}</div>
    </div>
  );
};

Flash.defaultProps = {
  type: 'alert',
  testid: 'flash',
  button: false,
  className: '',
};

export default Flash;
